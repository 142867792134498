import React from 'react'
import Markdown from 'markdown-to-jsx'

import Layout from '../components/layout'
import SEO from '../components/seo'
import NewsHero from '../components/hero/news'
import Breadcrumbs from '../components/breadcrumbs'
import Contact from '../components/contact'
import HorizontalForm from '../components/forms/horizontal'

import styles from '../styles/page.module.scss'

const BlogPage = data => {
  const page = data.pageContext

  return (
    <Layout>
      <SEO
        title={ page.title }
        description={ page.description }
        canonical={ `https://motorinjuryhelpline.com.au${ page.url }` }
      />

      <NewsHero
        leadLine="Description"
        heroLine={ page.title }
      />

      <main>
        <article className={ styles.content }>
          <div className={ styles.content__container }>
            <Breadcrumbs breadcrumbArray={ page.breadcrumbs } />
            <Markdown
              children={ page.content }
            />
          </div>
        </article>

        <div className={ styles.horizontalForm__background_padded }>
          <HorizontalForm
            formTitle={ page.formLine }
          />
        </div>

        <Contact />
      </main>
    </Layout>
  )
}

export default BlogPage
